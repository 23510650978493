@import '../../styles/color';

.list {
  padding: 0;
}

.item {
  position: relative;
  padding: 0 0 0.1rem 2rem;
  min-height: 5rem;
  margin: 0 0 4px 0;
  list-style: none;

  &:first-child > .timeline:before {
    animation: toggle 0.7s ease-in-out infinite;
  }
}

.timeline {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 1rem;
  &:before {
    box-sizing: border-box;
    background: transparent;
    border: 3px solid gray;
    border-radius: 100%;
    content: '';
    display: block;
    height: 12px;
    width: 12px;
    position: absolute;
    top: 4px;
    left: 0;
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
  }
  &:after {
    box-sizing: border-box;
    content: '';
    border: 1px dashed #ccd5db;
    border-radius: 15px;
    display: block;
    position: absolute;
    top: 24px;
    bottom: 0;
    left: 5px;
  }
}

.timeline_time {
  color: $typography-light;
  font-size: 0.85rem;
  font-weight: 500;
}

.company {
  display: inline-block;
  margin: 0.5rem 0 0 0;
  color: $primary-dark;
}

.link {
  color: $primary-dark;
  text-decoration: none;
  font-size: 0.9rem;
  span {
    margin-left: 0.1rem;
  }
}

.company_link {
  margin-left: 0.15rem;
  color: $primary;
}

.company_role {
  color: $typography-light;
  font-size: 0.9rem;
  font-weight: 200;
  margin-left: 0.5rem;
}

.company_description {
  margin: 0.7rem 0;
  font-size: 0.9rem;
  word-break: keep-all;
}

@keyframes toggle {
  to {
    background-color: $primary;
    border: $primary-light;
    opacity: 0;
    transform: scale(1.6);
  }
}
