@import '../../styles/responsive';

.container {
}

.title {
  font-family: 'Noto Serif KR', serif;
  font-size: 4rem;
  display: inline-block;
  margin: 2.5rem 0.3rem 1.5rem 0;
  @include responsive(small) {
    margin-bottom: -50px;
  }
}

.subtitle {
  display: inline-block;
  color: #6d6d6d;
  font-size: 1.1rem;
  font-weight: 300;
  @include responsive(small) {
    margin-left: 0.3rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
}
