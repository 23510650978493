@import '../../styles/responsive';

.particles {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  @include responsive(small) {
    display: none;
  }
}
