@import 'responsive';
@import 'typography';
@import 'color';

body {
  margin: 0;
  height: 100%;
  background: no-repeat fixed center;
  background-size: cover;
  transition: background 0.7s;

  @include responsive(small) {
    background-image: none !important;
  }
}