$screen-sm: 620px;
$screen-md: 992px;
$screen-lg: 1200px;

@mixin responsive($media) {
  @if $media == small {
    @media only screen and (max-width: $screen-sm) {
      @content;
    }
  } @else if $media == medium {
    @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
      @content;
    }
  } @else if $media == large {
    @media only screen and (min-width: $screen-md) {
      @content;
    }
  }
}
