@import '../../styles/color';
@import '../../styles/responsive';

.list {
  padding: 0;
}

.item {
  position: relative;
  padding-left: 1rem;
  min-height: 5rem;
  margin: 3rem 0 4rem 0;
  list-style: none;
}

.timeline {
  padding-left: 0.6rem;

  @include responsive(medium) {
    padding-left: 0;
  }
  @include responsive(small) {
    padding-left: 0;
  }

  &:before {
    display: block;
    box-sizing: border-box;
    content: '';
    border-top: 1px dashed #ccd5db;
    border-bottom: 1px dashed #ccd5db;
    position: absolute;

    top: 15px;
    left: -27px;
    width: 30px;

    @include responsive(medium) {
      top: 15px;
      left: -27px;
      width: 25px;
    }
    @include responsive(small) {
      top: 14px;
      left: -24px;
      width: 20px;
    }
  }

  &:after {
    box-sizing: border-box;
    display: block;
    position: absolute;
    background: transparent;
    border: 2px solid #b6bfc4;
    border-radius: 100%;
    content: '';
    transition: background 0.2s ease-in-out, border 0.2s ease-in-out;

    width: 10px;
    height: 10px;
    top: 11px;
    left: 5px;

    @include responsive(medium) {
      width: 8px;
      height: 8px;
      top: 12px;
      left: -2px;
    }
    @include responsive(small) {
      width: 8px;
      height: 8px;
      top: 11px;
      left: -4px;
    }
  }
}

.timeline_time {
  padding-top: 4px;
  color: $typography-light;
  font-size: 0.85rem;
  font-weight: 500;
}

.project_title {
  display: inline-block;
  margin: 0.3rem 0;
  padding-top: 0;
  color: $typography-light;
}

.project_link {
  color: $primary-dark;
  text-decoration: none;
  font-size: 0.75rem;
  span {
    margin-left: 0.1rem;
  }
}

.project_description {
  margin: 0.3rem 0;
  font-size: 0.9rem;
  color: $typography-dark;
  word-break: keep-all;
}

.tags_container {
  margin-bottom: 0.7rem;
}

.image_list {
  padding: 0;
}

.image_item {
  display: inline-block;
  list-style: none;
  vertical-align: top;
  cursor: pointer;
  transition: 100ms ease-in-out;
  &:hover {
    transform: scale(1.03);
  }

  @include responsive(small) {
    &:hover {
      transform: none;
    }
  }
}

.image_wrapper {
  @include responsive(small) {
    width: 100%;
    height: auto;
  }
}

.image {
  display: inline-block;
  object-fit: cover;
  margin: 0.6rem 0.6rem 0 0;
  vertical-align: top;
  border-radius: 4px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  transition: 100ms ease-in-out;
  &:hover {
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
      0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  }

  width: 120px;
  height: 80px;
  @include responsive(small) {
    width: 90px;
    height: 60px;
  }
}
