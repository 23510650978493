@import '../../styles/color';
@import "../../styles/responsive";

.list {
  padding-left: 1rem;
  @include responsive(small) {
    padding-left: 0.5rem;
  }
}

.item {
  margin-top: 2.5rem;
  list-style: none;
}

.name {
  color: $typography-light;
}

._list {
  padding-left: 2rem;
  @include responsive(small) {
    padding-left: 1rem;
  }
}

._item {
  margin-top: 1.5rem;
  list-style: none;
}

._name {
  color: $typography-light;
}

.__list {
}

.__item {
  font-size: 0.9rem;
  color: $typography-light;
  line-height: 1.8rem;
}

.__item_description {
}
