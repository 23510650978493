@import '../../styles/responsive';
@import '../../styles/color';

.section {
}

.list {
  padding-left: 0.5rem;
}

.item {
  list-style: none;
  line-height: 1.8;
  svg {
    color: $typography-light;
  }
  a {
    color: $primary-dark;
    padding-left: 0.2rem;
  }
}
