@import '../../styles/responsive';

.paper {
  margin: 0 auto;
  max-width: 800px;
  min-height: 500px;
  min-width: 250px;
  padding: 2rem 4rem;
  transition: width 0.3s;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.95);
  -webkit-box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px,
  rgba(0, 0, 0, 0.12) 0 1px 4px;
  -moz-box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px,
  rgba(0, 0, 0, 0.12) 0 1px 4px;
  box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;

  @include responsive(small) {
    padding: 0.5rem 2rem;
  }
  @media print {
    box-shadow: none;
  }
}