@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import '../../styles/responsive';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  right: 15%;
  bottom: auto;
  background-color: #ffffff;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14),
    0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  outline: none;

  @include responsive(medium) {
    left: 50px;
    right: 50px;
  }

  @include responsive(small) {
    left: 16px;
    right: 16px;
  }
}

.contents {
  position: relative;
  background-color: #232323;
  padding-top: 5rem;
  @include responsive(small) {
    padding-top: 4rem;
  }
}

.close_button {
  position: absolute;
  font-size: 2.8rem;
  right: 1rem;
  top: 1rem;
  z-index: 9999;
  background-color: transparent;
  outline: none;
  border: none;
  color: #ffffff;
  cursor: pointer;

  &:hover {
    transition: 200ms;
    transform: scale(1.2);
  }

  @include responsive(medium) {
    font-size: 2.5rem;
  }

  @include responsive(small) {
    font-size: 1.6rem;
  }

  svg {
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
  }
}

.loader_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  min-height: 200px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
