@import './styles/responsive';

.App {
  padding: 30px;

  @include responsive(medium) {
    padding: 16px;
  }
  @include responsive(small) {
    padding: 0;
  }
  @media print {
    padding: 0;
  }
}
