@import '../../styles/responsive';

.paragraph {
  font-size: 20px;
  font-weight: 300;

  strong {
    font-weight: 500;
  }

  @include responsive(small) {
    font-size: 18px;
  }
}
