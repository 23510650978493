@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:100,300,400,500|Noto+Serif+KR:200,300,400,500,700');
@import 'responsive';
@import 'color';

html {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $typography;

  @include responsive(large) {
    font-size: 18px;
  }
  @include responsive(medium) {
    font-size: 18px;
  }
  @include responsive(small) {
    font-size: 16px;
  }
  @media print {
    font-size: 16px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 2.4rem;
  margin: 2rem 0;
}
h2 {
  font-size: 2.1em;
  margin: 1.6rem 0;
}
h3 {
  font-size: 1.8rem;
  margin: 1.3rem 0;
}
h4 {
  font-size: 1.4rem;
  margin: 1rem 0;
}
h5 {
  font-size: 1.1rem;
  margin: 0.9rem 0;
}

.Noto-Sans {
  font-family: 'Noto Sans KR', sans-serif;
}

.Noto-Serif {
  font-family: 'Noto Serif KR', serif;
}
