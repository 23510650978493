@import '../../styles/color';

.tag {
  display: inline-block;
  margin: 0.5rem 0.2rem 0 0;
  padding: 0.02rem 0.5rem 0.1rem 0.5rem;
  font-size: 0.7rem;
  border: 1px dashed $primary;
  color: $primary-dark;
  border-radius: 7px;
}
