@import '~react-image-gallery/styles/scss/image-gallery.scss';

.image-gallery-slide {
  outline: none;
}

.image-gallery-image {
  max-height: 80vh !important;
  cursor: alias;
}

button.image-gallery-thumbnail {
  min-height: 68px !important;
  object-fit: contain;
}

.image-gallery-thumbnail-image {
  max-height: 60px;
  object-fit: contain;
}
